<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$EntitiesName.Imputation"
        :edit="edit"
        :save="save"
        :cancel="cancel"
        :remove="remove"
      >
        <h1 class="title" data-test="sel-role-header">
          <breadcrumb location="/imputations" text="Imputation"></breadcrumb>
          {{ imputation.eotp }}
        </h1>
      </page-header>

      <div class="panel infos">
        <div class="panel-heading has-background-primary">
          <span>Informations</span>
        </div>
        <div class="panel-block is-block no-border">
          <div class="columns">
            <div class="column is-half" v-if="imputation">
              <text-field
                label="eOTP"
                v-model="imputation.eotp"
                required
              ></text-field>
              <textarea-field
                label="Description"
                v-model="imputation.description"
                data-test="sel-role-description"
              ></textarea-field>
              <many2one-field
                label="Catégorie"
                v-model="imputation.category"
                :fetch="(p) => fetchSubCategories(p)"
                reference="name"
                :columns="{'parent.name': 'Parent', name: 'Nom'}"
                :inline="true"
                :hasFilter="true"
                :edit="editMode"
                required
                name="subcategory2one"
              >
              </many2one-field>
              <checkbox-field label="Active ?" v-model="imputation.active"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "imputation",
  props: ["id"],
  computed: {
    ...mapGetters({
      editMode: "App/isEdition",
    }),
  },
  data() {
    return {
      imputation: {},
    };
  },
  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      axios.get(`/imputations/${this.id}`).then((r) => {
        this.imputation = r.data;
        this.$store.dispatch("App/setEditing", false);
      });
    },
    remove() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios
            .delete(`/imputations/${this.id}`)
            .then((res) => {
              if (res.data.success) {
                this.$router.replace("/imputations");
              }
            }),
      );
    },
    save() {
      return axios
        .put(`/imputations/${this.id}`, this.imputation)
        .then((response) => {
          if (response.data.success) {
            this.fetchData();
          }
        });
    },
    edit() {
      this.$store.dispatch("App/setEditing", true);
    },
    cancel() {
      this.fetchData();
    },
    fetchSubCategories(p) {
      if(this.imputation.category) {
        return this.$Api.Category.fetchSubCategoriesForParent(p, this.imputation.category.parent.id)
      }
      return this.$Api.Category.fetchSubCategories(p)
    }
  },
};
</script>

<style lang='scss' scoped>

</style>